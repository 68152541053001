import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
  variant?: Variant
}

export const Service = memo(function Service({
  icon,
  label,
  variant = 'default',
}: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variant={variant}
      variants={variants}
    >
      {icon ? <Icon src={icon} alt={label} width="40" height="40" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)<ContainerProps>`
  width: calc(33.333% - 8.333vw);
  margin: 5rem 8.333vw 0 0;

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          width: calc(50% - 9.722vw);
          margin-right: 9.722vw;
        `
    }
  }}

  @media (max-width: 1023px) {
    width: 50%;
    margin: 3.125rem 0 0;
  }
`

const Icon = styled.img`
  width: auto;
  max-width: 3.125rem;
  height: 2.25rem;
  margin-bottom: 1.25rem;

  @media (max-width: 1023px) {
    height: 1.875rem;
  }
`

const Label = styled.div`
  max-width: 9.375rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.0375rem;
  line-height: 1.125rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin: auto;
    padding: 0 0.5rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
