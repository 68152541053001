import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  image?: ImageProps
  services: ServiceProps[]
  title?: string
  variant?: Variant
}

export const IconsServices = memo(function IconsServices({
  image,
  services,
  title,
  variant = 'default',
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <Services row wrap>
          {services.map((item, index) => (
            <Service key={index} variant={variant} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 9.8125rem;

  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
`

const LeftSide = styled.div`
  width: 50%;
  height: 89.7vh;
  max-height: 48.125rem;
  position: relative;

  img {
    width: calc(100% - 10vw);
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    padding-bottom: 63%;
    max-height: none;

    img {
      width: 100%;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 6.6875rem 10vw 0 0;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.3125rem 2rem 0;
    text-align: center;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  position: relative;
  text-transform: uppercase;
  &:before,
  &:after {
    content: '';
    width: 5.556vw;
    height: 0.125rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:before {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    right: calc(100% + 4.444vw);
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    right: calc(100% + 10vw);
  }

  @media (max-width: 1023px) {
    &:before,
    &:after {
      width: 0.0625rem;
      height: 1.875rem;
      right: 50%;
      transform: none;
    }
    &:before {
      top: -3.3125rem;
    }
    &:after {
      top: -5.1875rem;
    }
  }
`

const Services = styled(FlexBox)`
  margin-right: -7.5rem;

  @media (max-width: 1023px) {
    margin-right: 0;
    padding-bottom: 3.75rem;
  }
`

export type Variant = 'default' | 'compact'
